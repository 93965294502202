.showroom {
    margin-bottom: 20px;

    &-header {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;

        h2 {
            flex: 0 1 auto;
            margin-right: 20px;
            font-weight: 400;
        }

        nav {
            display: flex;
            flex: 1 1 auto;

            .active {
                font-weight: 600;
                border-bottom: 1px solid;
            }

            div {
                margin: 0 10px;
                cursor: pointer;
            }
        }

        &-links {
            display: flex;
            flex: 0 1 auto;

            a {
                display: none;
                position: relative;
                padding-right: 18px;

                i {
                    position: absolute;
                    right: 0;
                    top: 5px;
                }

                &.show {
                    display: flex;
                }
            }
        }
    }

    &-sections {
        display: flex;
        align-items: center;
        height: 150px;

        > * {
            width: 100%;
            display: none;

            &.show {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &-section-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 0px;
        margin-right: 10px;

        [data-webm-section='category-brand'] & {
            .item-image {
                background-size: 85% auto;
            }
        }

        .item-image {
            display: block;
            margin-bottom: 8px;
            height: 60px;
            width: 100%;

            background: {
                size: contain;
                position: center;
                repeat: no-repeat;
            }

            &.volkswagen {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/volkswagen.png);
            }

            &.chevrolet {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/chevrolet.png);
            }

            &.fiat {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/fiat.png);
            }

            &.ford {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/ford.png);
            }

            &.peugeot {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/peugeot.png);
            }

            &.renault {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/renault.png);
            }

            &.citron {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/citroen.png);
            }

            &.toyota {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/toyota.png);
            }

            &.acura {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/acura.png);
            }

            &.honda {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/honda.png);
            }

            &.jac {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/jac.png);
            }

            &.mazda {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/mazda.png);
            }

            &.mitsubishi {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/mitsubishi.png);
            }

            &.hyundai {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/hyundai.png);
            }

            &.kia {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/kia.png);
            }

            &.suzuki {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/suzuki.png);
            }

            &.nissan {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/nissan.png);
            }

            &.subaru {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/subaru.png);
            }

            &.holden {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/holden.png);
                background-size: 60% auto;
            }

            &.bmw {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/bmw.png);
            }

            &.skoda {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/skoda.png);
            }

            &.seat {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/seat.png);
            }

            &.audi {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/audi.png);
            }

            &.opel {
                background-image: url(https://resource.csnstatic.com/retail/manufacturer-logos/opel.png);
            }


            .carsales &, .redbookcars &, .soloautos &, .demotores &, .chileautos & {
                &.convertible, &.deportivo {
                    background-image: url(./Images/body-types/convertible.png);
                }

                &.sedn, &.sedan {
                    background-image: url(./Images/body-types/sedan.png);
                }

                &.suv {
                    background-image: url(./Images/body-types/suv.png);
                }

                &.ute, &.pickup, &.pick-up, &.doble-cabina {
                    background-image: url(./Images/body-types/ute.png);
                }

                &.minivan {
                    background-image: url(./Images/body-types/people-mover.png);
                }

                &.hatch, &.hatchback {
                    background-image: url(./Images/body-types/hatch.png);
                }

                &.coup, &.coupe {
                    background-image: url(./Images/body-types/coupe.png);
                }

                &.wagon, &.vagoneta, &.station-wagon, &.familiar {
                    background-image: url(./Images/body-types/wagon.png);
                }

                &.furgn {
                    background-image: url(./Images/body-types/comm-van.png);
                }
            }

            .bikesales &, .redbookbikes &, .bikesalesshowroom & {
                &.learner {
                    background-image: url(./Images/categories/bikesales/learner.png);
                }

                &.road-bikes {
                    background-image: url(./Images/categories/bikesales/road-bikes.png);
                }

                &.cruisers {
                    background-image: url(./Images/categories/bikesales/cruisers.png);
                }

                &.atvs-quads {
                    background-image: url(./Images/categories/bikesales/atvs-quads.png);
                }

                &.dirt-bikes {
                    background-image: url(./Images/categories/bikesales/dirt-bikes.png);
                }

                &.sxs-utv {
                    background-image: url(./Images/categories/bikesales/sxs-utv.png);
                }

                &.scooters {
                    background-image: url(./Images/categories/bikesales/scooters.png);
                }

                &.naked {
                    background-image: url(./Images/categories/bikesales/naked.jpg);
                }
            }

            .boatsales &, .redbookboats &, .boatsalesshowroom & {
                &.leisure {
                    background-image: url(./Images/categories/boatsales/leisure.png);
                }

                &.cruiser {
                    background-image: url(./Images/categories/boatsales/cruiser.png);
                }

                &.pwc {
                    background-image: url(./Images/categories/boatsales/pwc.png);
                }

                &.ski-boat {
                    background-image: url(./Images/categories/boatsales/ski-boat.png);
                }

                &.fishing {
                    background-image: url(./Images/categories/boatsales/fishing.png);
                }

                &.sail, &.sailing-yacht {
                    background-image: url(./Images/categories/boatsales/sail.png);
                }

                &.bowrider {
                    background-image: url(./Images/categories/boatsales/bowrider.jpg);
                }

                &.centre-console {
                    background-image: url(./Images/categories/boatsales/centre-console.jpg);
                }

                &.cuddy-cabin {
                    background-image: url(./Images/categories/boatsales/cuddy-cabin.jpg);
                }

                &.open-dinghy {
                    background-image: url(./Images/categories/boatsales/dinghy.jpg);
                }

                &.side-console {
                    background-image: url(./Images/categories/boatsales/side-console.jpg);
                }
            }

            .trucksales & {
                &.trucks {
                    background-image: url(./Images/categories/trucksales/trucks.png);
                }

                &.trailers {
                    background-image: url(./Images/categories/trucksales/trailers.png);
                }

                &.buses {
                    background-image: url(./Images/categories/trucksales/buses.png);
                }

                &.prime-movers {
                    background-image: url(./Images/categories/trucksales/prime-movers.png);
                }

                &.tippers {
                    background-image: url(./Images/categories/trucksales/tippers.png);
                }

                &.tray-trucks {
                    background-image: url(./Images/categories/trucksales/tray-trucks.png);
                }
            }

            .trucksalesshowroom & {
                &.fuso {
                    background-image: url(./Images/brands/fuso.png);
                }

                &.hino {
                    background-image: url(./Images/brands/hino.png);
                }

                &.isuzu {
                    background-image: url(./Images/brands/isuzu.png);
                }

                &.iveco {
                    width: 70%;
                    background-image: url(./Images/brands/iveco.png);
                }

                &.kenworth {
                    width: 75%;
                    background-image: url(./Images/brands/kenworth.png);
                }

                &.u-d {
                    background-image: url(./Images/brands/u-d.png);
                }

                &.volvo {
                    background-image: url(./Images/brands/volvo.png);
                }

                &.mack {
                    background-image: url(./Images/brands/mack.png);
                }

                &.man {
                    background-image: url(./Images/brands/man.png);
                }
            }

            .caravancampingsales &, .caravancampingsalesshowroom & {
                &.caravan, &.caravans {
                    background-image: url(./Images/categories/caravancampingsales/caravans.png);
                }

                &.pop-tops {
                    background-image: url(./Images/categories/caravancampingsales/pop-tops.png);
                }

                &.expander, &.expanders {
                    background-image: url(./Images/categories/caravancampingsales/expander.png);
                }

                &.off-road-caravan {
                    background-image: url(./Images/categories/caravancampingsales/off-road-caravan.png);
                }

                &._5th-wheelers {
                    background-image: url(./Images/categories/caravancampingsales/5th-wheelers.png);
                }

                &.camper-trailer, &.camping-trailers {
                    background-image: url(./Images/categories/caravancampingsales/camper-trailer.png);
                }

                &.off-road-camper {
                    background-image: url(./Images/categories/caravancampingsales/off-road-camper.png);
                }

                &.motorhome, &.motorhomes {
                    background-image: url(./Images/categories/caravancampingsales/motorhome.png);
                }

                &.parts-accessories {
                    background-image: url(./Images/categories/caravancampingsales/parts-accessories.png);
                }

                &.parts-accessories-new {
                    background-image: url(./Images/categories/caravancampingsales/parts-accessories-new.png);
                }
            }

            .farmmachinerysales & {
                &.tractors {
                    background-image: url(./Images/categories/farmmachinerysales/tractors.png);
                }

                &.harvesting {
                    background-image: url(./Images/categories/farmmachinerysales/harvesting.png);
                }

                &.tillage-seeding {
                    background-image: url(./Images/categories/farmmachinerysales/tillage-seeding.png);
                }

                &.hay-silage {
                    background-image: url(./Images/categories/farmmachinerysales/hay-silage.png);
                }

                &.spraying {
                    background-image: url(./Images/categories/farmmachinerysales/spraying.png);
                }

                &.irrigation-equipment {
                    background-image: url(./Images/categories/farmmachinerysales/irrigation-equipment.png);
                }

                &.trucks {
                    background-image: url(./Images/categories/farmmachinerysales/trucks.png);
                }

                &.bikes-atvs-utvs {
                    background-image: url(./Images/categories/farmmachinerysales/bikes-atvs-utvs.png);
                }
            }

            .constructionsales & {
                &.excavators {
                    background-image: url(./Images/categories/constructionsales/excavators.png);
                }

                &.cranes-lifting {
                    background-image: url(./Images/categories/constructionsales/cranes-lifting.png);
                }

                &.dozers {
                    background-image: url(./Images/categories/constructionsales/dozers.png);
                }

                &.skidsteer-loader {
                    background-image: url(./Images/categories/constructionsales/skidsteer-loader.png);
                }

                &.wheel-loader {
                    background-image: url(./Images/categories/constructionsales/wheel-loader.png);
                }

                &.trucks {
                    background-image: url(./Images/categories/constructionsales/trucks.png);
                }

                &.trailers {
                    background-image: url(./Images/categories/constructionsales/trailers.png);
                }

                &.graders {
                    background-image: url(./Images/categories/constructionsales/graders.png);
                }
            }
        }

        .showroom__list__link {
            /* styles for nativead - still uses old classes */
            display: flex;
            flex-flow: column nowrap;
            width: 100%;

            .hplp-image {
                margin-bottom: 15px;
                height: 60px !important;
            }
        }

        span {
            text-align: center;
        }

        .sponsored {
            display: block;
            color: #757575;
            font-size: 12px;
        }

        &.bodydriver {
            display: none;
        }
    }
}
